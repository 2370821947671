.date-picker {
    border-radius: 8px;
    width: 100%;
    padding: 1rem;
    margin-top: 0.5rem;
    border-style: solid;
    border-color: #a8b4af;
    border-width: 1px;
    cursor: pointer;
}

.date-picker:hover {
    border-color: black;
}
